// src/Cards.js
import React, { useState, useEffect } from "react";
import Card from "./Card";
import SearchCards from "./SearchCards";

const Cards = () => {
  const [cardsData, setCardsData] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let allData = [];
      let page = 1;
      let totalPages = 1;
  
      // Loop until all pages are fetched
      while (page <= totalPages) {
        const response = await fetch(
          `https://rulefactory.ch/wp-json/wp/v2/rules?page=${page}`
        );
        const data = await response.json();
        allData = [...allData, ...data];
        totalPages = response.headers.get("X-WP-TotalPages");
        page++;
      }
  
      // Sort cards by cardType
      allData.sort((a, b) => {
        const typeA =
          a["toolset-meta"]["regel-konfiguration"]["regeltyp"]["raw"];
        const typeB =
          b["toolset-meta"]["regel-konfiguration"]["regeltyp"]["raw"];
        return typeA.localeCompare(typeB);
      });
  
      // Filter out cards where type is not equal to "1"
      const filteredData = allData.filter(
        (card) =>
          card["toolset-meta"]["regel-konfiguration"]["kartenregel"]["raw"] ===
          "1"
      );
  
      // Update both cardsData and filteredCards states
      setCardsData(filteredData);
      setFilteredCards(filteredData);
    };
  
    fetchData();
  }, []);
  

  return (
    <div className="cards bootstrap-wrapper">
      <h1>Cards</h1>
      <SearchCards cardsData={cardsData} setFilteredCards={setFilteredCards} />
      <div className="row">
        {filteredCards.map((card) => (
          <Card
            key={card.id}
            name={card.title.rendered}
            description={
              card["toolset-meta"]["regel-details"]["deutsch"]["raw"]
            }
            img={card["toolset-meta"]["regel-konfiguration"]["icon"]["raw"]}
            cardType={
              card["toolset-meta"]["regel-konfiguration"]["regeltyp"]["raw"]
            }
            type={
              card["toolset-meta"]["regel-konfiguration"]["kartenregel"]["raw"]
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Cards;
