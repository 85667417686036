import React from "react";

const Card = (props) => {
  const { name, description, img, cardType, type } = props;

  function GetColor(cardType) {
    if (cardType === "3") {
      return "card-container special-card color-dependent";
    } else if (cardType === "4") {
      return "card-container special-card color-independent";
    } else if (cardType === "5") {
      return "card-container special-card unique";
    } else if (cardType === "7") {
      return "card-container power-card";
    } else {
      return "card-container";
    }
  }

  return (
    <div className="col-md-4 card-box" id={type}>
      <div className="card" id={cardType}>
        <div className={GetColor(cardType)}>
          <div className="row no-gutters">
            <div className="col-md-12 icon-container">
              <img src={img} className="card-img" />
            </div>
            <div className="col-md-12">
              <div className="card-body">
                <h5 className="card-title">{name}</h5>
                <p
                  className="card-text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
