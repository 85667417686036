// src/SearchCards.js
import React, { useState } from 'react';

const SearchCards = ({ cardsData, setFilteredCards }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        const filteredCards = cardsData.filter(card => card.title.rendered.toLowerCase().includes(value.toLowerCase()));
        setFilteredCards(filteredCards);
    };

    return (
        <div className="search-cards">
            <input
                type="text"
                placeholder="Search cards..."
                value={searchTerm}
                onChange={handleSearch}
            />
        </div>
    );
};

export default SearchCards;
